import React, {Component} from 'react';
import axios from 'axios';
import {Form,Input, Button, Select, Result, Collapse} from 'antd';

import { trackPromise } from 'react-promise-tracker';
import {Spinner} from "./spinner";



let url_path = 'https://headlinechecker.demos.gplsi.es/'
let CancelToken = axios.CancelToken;
let cancel;

export default class From_predict extends Component {


    constructor(props) {
        super(props);
        this.state = {
            results: [],
            data: [],
            isAvailable: false,
            codeStatus:"",
            showCodeStatus:false,
            subTitle:"",
            messageCodeStatus:"",
            originalFile: "",
            uploadFile: false,
            type_processing: "",
            processing:"Select a option",
            fileDownloadUrl: null,
            select_processing: ""
        }
    }

    onProcessingChange = value => {
        switch (value) {
            case "single":
                break;
            case "batch":
                break;
        }
    }


    onReset= () =>{
        this.form.resetFields();
        this.setState({isAvailable: false, results: [],
                            showCodeStatus: false});
        try{
            cancel();
        }
        catch (error){
            console.log(error)
        }
        this.setState(this.baseState);

    }

    backHome= () =>{
        this.setState({isAvailable: false, results: [],
            showCodeStatus: false});
    }

    handle_select = e =>{
        if (this.state.processing !== e && this.state.processing !== "Select a option"){
            this.setState({isAvailable: false, results: []});
        }
        this.state.processing = e;
    }

    download = (event) => {

        event.preventDefault();
        // Prepare the file
        let output;
        output = JSON.stringify(this.state.results,null,4);
        // Download it
        const blob = new Blob([output]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        this.setState ({fileDownloadUrl: fileDownloadUrl},
            () => {
                this.dofileDownload.click();
                URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
                this.setState({fileDownloadUrl: ""})
            })
    }


    handle = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            // console.log("e.target.result", e.target.result);
            this.setState({originalFile: JSON.parse(e.target.result)});
        };
    };


    onFinish = async values => {
            let jsonResponse_text = [];
            let jsonResponse_clas = [];
            let json_in = [values];


            if(values.processing == "single"){
                if(this.state.data.length !== 0){
                    json_in = this.state.data;
                    json_in['id_aiticle'] = 1;
                }
            }
            else{
                json_in = this.state.originalFile
            }
            let tempState = null;
            await trackPromise(
                 axios.post(url_path+'list_metrics', json_in ,  {
                     cancelToken: new CancelToken(function executor(c) {
                         // The executor function receives a cancel function as a parameter
                         cancel = c;
                     })}
                ).then(response => {
                    if(response.status === 200){
                        jsonResponse_text = response.data;
                    }
                }).catch(function(exception) {
                     // console.log(exception)
                     // tempState = this.check_response(exception)
                     // let tempState = null
                     if (axios.isCancel(exception)) {
                         console.log('Request canceled', exception.message);
                     }else if(exception.response!=='undefined'){
                         tempState= {showCodeStatus: true, codeStatus:"error", subTitle:"Submission Failed"}
                     }
                     else if(exception.response.status === 400){
                         tempState= {showCodeStatus: true, codeStatus:"error", subTitle:"Submission Failed",
                             messageCodeStatus:exception.response.statusText}
                     }else if(exception.response.status === 500){
                         tempState={showCodeStatus: true, codeStatus:500, subTitle:500,
                             messageCodeStatus:"Sorry, something went wrong."}
                     }else if(exception.response.status === 404){
                         tempState= {showCodeStatus: true, codeStatus:404, subTitle:404,
                             messageCodeStatus:"Sorry, the page you visited does not exist."}
                     }else if(exception.response.status === 403){
                         tempState= {showCodeStatus: true, codeStatus:403, subTitle:403,
                             messageCodeStatus:"Sorry, you are not authorized to access this page."}
                     }
                 })
            );
            if(jsonResponse_text.length!==0) {
                let jsonDataIn = [];
                for (let i = 0; i < jsonResponse_text.length; i++) {
                    let jsonItem = jsonResponse_text[i];
                    jsonDataIn.push({
                        'headline': jsonItem['headline'],
                        'body': jsonItem['summary'],
                        'cosine_similarity': jsonItem['cosine_similarity'],
                        'overlap': jsonItem['overlap'],
                        'hellinger_score': jsonItem['hellinger_score'],
                        'kullback_leibler_score': jsonItem['kullback_leibler_score']
                    })
                }

                await trackPromise(
                    axios.post(url_path+'combined_classification', jsonDataIn, {
                            cancelToken: new CancelToken(function executor(c) {
                                // The executor function receives a cancel function as a parameter
                                cancel = c;
                            })
                        }
                    ).then(response1 => {
                        if (response1.status === 200) {
                            let jsonDataOut = [];
                            jsonResponse_clas = response1.data['predict_value'];
                            for (let i = 0; i < jsonResponse_clas.length; i++) {
                                jsonDataOut.push({
                                    'id_article': json_in[i]['id_article'],
                                    'predict_value': jsonResponse_clas[i],
                                    'summary': jsonResponse_text[i]['summary'],
                                    'headline': jsonResponse_text[i]['headline']
                                });
                            }
                            this.setState({
                                // results: JSON.stringify(jsonDataOut, null, 4),
                                results: jsonDataOut,
                                isAvailable: true
                            });
                        }
                    }).catch(function (exception) {
                        // tempState = this.check_response(exception);
                        if (axios.isCancel(exception)) {
                            console.log('Request canceled', exception.message);
                        }else if(exception.response!=='undefined'){
                            tempState= {showCodeStatus: true, codeStatus:"error", subTitle:"Submission Failed"}
                        }
                        else if(exception.response.status === 400){
                            tempState= {showCodeStatus: true, codeStatus:"error", subTitle:"Submission Failed",
                                messageCodeStatus:exception.response.statusText}
                        }else if(exception.response.status === 500){
                            tempState={showCodeStatus: true, codeStatus:500, subTitle:500,
                                messageCodeStatus:"Sorry, something went wrong."}
                        }else if(exception.response.status === 404){
                            tempState= {showCodeStatus: true, codeStatus:404, subTitle:404,
                                messageCodeStatus:"Sorry, the page you visited does not exist."}
                        }else if(exception.response.status === 403){
                            tempState= {showCodeStatus: true, codeStatus:403, subTitle:403,
                                messageCodeStatus:"Sorry, you are not authorized to access this page."}
                        }
                    })
                );
            }
            if(tempState!==null){
                this.setState({showCodeStatus: tempState.showCodeStatus, codeStatus:tempState.codeStatus,
                    subTitle:tempState.subTitle});
            // , messageCodeStatus:tempState.messageCodeStatus
                console.log(tempState.messageCodeStatus);
            }
    }

    check_response = exception => {
        let tempState = null
        if (axios.isCancel(exception)) {
            console.log('Request canceled', exception.message);
        }else if(exception.response!=='undefined'){
            tempState= {showCodeStatus: true, codeStatus:"error", subTitle:"Submission Failed"}
        }
        else if(exception.response.status === 400){
            tempState= {showCodeStatus: true, codeStatus:"error", subTitle:"Submission Failed",
                messageCodeStatus:exception.response.statusText}
        }else if(exception.response.status === 500){
            tempState={showCodeStatus: true, codeStatus:500, subTitle:500,
                messageCodeStatus:"Sorry, something went wrong."}
        }else if(exception.response.status === 404){
            tempState= {showCodeStatus: true, codeStatus:404, subTitle:404,
                messageCodeStatus:"Sorry, the page you visited does not exist."}
        }else if(exception.response.status === 403){
            tempState= {showCodeStatus: true, codeStatus:403, subTitle:403,
                messageCodeStatus:"Sorry, you are not authorized to access this page."}
        }
        return tempState;
    }


    getColor = value => {
        switch (value) {
            case "agree":
                return "green-header";
            case "disagree":
                return "red-header";
            case "discuss":
                return "blue-header";
            case "unrelated":
                return "orange-header";
            default:
                break;
        }
    }

   render() {

        const validateMessages = {
            required: 'This field is required!',
        };

       const { Option } = Select;

       const { TextArea } = Input;
       const { Panel } = Collapse;
       const style = { background: '#0092ff', padding: '8px 0' };


        return (
            <>
                {!this.state.showCodeStatus &&
                    <Form ref={form => this.form = form} id="submit-form" layout={'horizontal'} onFinish={this.onFinish} validateMessages={validateMessages}>

                        <Form.Item name="processing" label="Choose a type of processing">
                            <Select
                                placeholder="Select a option"

                                // ref={select_processing => this.select_processing = select_processing}
                                id="processing-select"
                                allowClear
                                onChange={e => this.handle_select(e)}
                                style={{ width: 200 }}>
                                <Option value="single">Single Processing</Option>
                                <Option value="batch">Batch processing</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.processing !== currentValues.processing}
                        >
                            {({ getFieldValue }) => {
                                return getFieldValue('processing') === 'single' ? (

                                    <div>
                                        <Form.Item label="Headline" name={'headline'} rules={[{ required: true, },
                                        ]}>
                                            <Input placeholder="Insert headline" />
                                        </Form.Item>
                                        <Form.Item label="Body text" name={'body'} rules={[{ required: true },
                                        ]}>
                                            <TextArea  rows={4} placeholder="Insert body text" />

                                        </Form.Item>
                                    </div>

                                ) : null;
                            }}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.processing !== currentValues.processing}
                        >
                            {({ getFieldValue }) => {
                                return getFieldValue('processing') === 'batch' ? (
                                   <Form.Item name="row_1">
                                        <Input.Group compact>
                                            <Form.Item>
                                                <input
                                                    type="file"
                                                    accept=".json"
                                                    className="mt-2 btn btn-dark w-75"
                                                    onChange={e => this.handle(e)}
                                                />

                                            </Form.Item>
                                            <Form.Item
                                                name="detail"
                                                style={{ display: 'inline-block', margin: '0 15px' }}
                                            >

                                                    <p>Json file format</p>
                                                    <p>["id_article":"", "headline": "", "body": ""]</p>

                                            </Form.Item>
                                        </Input.Group>
                                   </Form.Item>
                                ) : null;
                            }}
                        </Form.Item>

                        {/*Result of predict*/}
                        <Form.Item>
                          {this.state.isAvailable &&
                                <Collapse className="content" defaultActiveKey={['1']}>
                                    {/*color={this.getColor(user.predict_value.toUpperCase())}*/}
                                    {this.state.results.map((user) => (
                                        <Panel className={this.getColor(user.predict_value)} header={user.predict_value.toUpperCase()} key={user.predict_value}>
                                            <label>Summary:</label>
                                            <div className="user">{user.summary}</div>
                                        </Panel>

                                    ))}
                                </Collapse>
                            }
                            <Spinner />

                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.processing !== currentValues.processing}
                        >

                            {({ getFieldValue }) => {
                                return getFieldValue('processing') === 'batch' || getFieldValue('processing') === 'single' ? (
                                    <Form.Item>

                                        <Form.Item>
                                                <Button style={{ marginLeft: '.5rem' }}  htmlType="submit" type="primary">Submit</Button>
                                                <Button style={{ marginLeft: '.5rem' }} onClick={this.onReset}>Reset</Button>
                                                {this.state.isAvailable && getFieldValue('processing') === 'batch' &&
                                                        <Button style={{ marginLeft: '.5rem' }} onClick={this.download}>Save prediction</Button>
                                                }
                                                <a style={{display: "none"}} download="predict.json"
                                               href={this.state.fileDownloadUrl} ref={e=>this.dofileDownload = e} >download it</a>
                                        </Form.Item>
                                    </Form.Item>
                                ) : null;
                            }}
                        </Form.Item>
                    </Form>
                }
                {this.state.showCodeStatus &&
                    <Result
                        status={this.state.codeStatus}
                        title={this.state.subTitle}
                        subTitle={this.state.messageCodeStatus}
                        extra={<Button type="primary" onClick={this.backHome}>Back Home</Button>}
                    />
                }

            </>
        );
    }
}
