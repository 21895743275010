import React from 'react'
import From_predict from "./components/Form_predict";
import { Layout } from 'antd';

const { Header, Content} = Layout;

export const Home = () =>(
    <Layout>
        <Header></Header>

        <Content style={{ padding: '30px 50px' }}>
            <div>
                <h1>HeadlineStanceChecker</h1>
                <From_predict/>
            </div>
        </Content>
    </Layout>

)