import React, { useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import * as Loader from "react-loader-spinner";
import "./spinner.css";

export const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker({area: props.area});


    return (

        promiseInProgress && (
             <div className="spinner">
                <Loader.ThreeDots color="#2BAD60" height="100" width="100" />
             </div>

        )
    );
};


